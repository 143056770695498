import { useMutation } from '@tanstack/react-query';
import { fetchExternalDashboardById } from 'actions/commonActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';

export const useFetchExternalDashboardData = () => {
  const snackbar = useSnackbar();

  return useMutation<
    DashboardData,
    ApiError,
    { dashboardId: string; onSuccess?: () => void }
  >({
    mutationFn: ({ dashboardId }) => fetchExternalDashboardById(dashboardId),
    onSuccess: (data, { dashboardId, onSuccess }) => {
      queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), data);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
