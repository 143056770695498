import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, MessageTypes, queryKeys } from 'enums';

import { SelectedFilterItem, WidgetFilter } from '../WidgetFilter';
import { ZoomableIcicle } from './ZoomableIcicle';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  summary?: string;
  data?: DashboardParams;
  isTextWidgetsProcessing?: boolean;
}

export const ZoomableIcicleChart: FC<Props> = ({
  title,
  summary,
  data,
  isTextWidgetsProcessing
}) => {
  const { dashboardId } = useParams();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<SelectedFilterItem>(null);

  const zoomableSunburstContainerRef = useRef<HTMLDivElement | null>(null);
  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const showFilter = dashboardData?.revision && dashboardData.revision > 2;

  return (
    <div className={styles.container}>
      <CommonTooltip
        title={title || t('Page.Dashboard.ZoomableIcicle.Title')}
        className={cn(styles.title, 'overflowed-text-multiline')}
      />
      {showFilter && (
        <WidgetFilter
          selectedItem={selectedItem}
          className={styles['filter-container']}
          widgetType={InsightsType.HierarchyProducts}
          tooltip={t('Page.Dashboard.ZoomableIcicle.ChooseFilter')}
          notes={{
            active: t('Page.Dashboard.ZoomableIcicle.NoteActive', {
              product: selectedItem?.name || '-'
            }),
            inactive: t('Page.Dashboard.ZoomableIcicle.Note')
          }}
        />
      )}
      {data?.data?.length ? (
        <div
          className={styles['zoomable-sunburst-chart-container']}
          ref={zoomableSunburstContainerRef}
        >
          <ZoomableIcicle data={data.data[0]} selectItem={selectItem} />
        </div>
      ) : (
        <WidgetEmptyState />
      )}
      {(isTextWidgetsProcessing || summary) && (
        <div className={styles.summary}>
          <ChatStatement
            showAvatar={false}
            maxLettersAmount={1200}
            isTextWidgetsProcessing={isTextWidgetsProcessing}
            data={{ type: MessageTypes.answer, statement: summary }}
          />
        </div>
      )}
    </div>
  );
};
