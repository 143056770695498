import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Backdrop, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { DashboardFilterModal } from 'components/DashboardFilterModal';
import { DashboardData } from 'entities/Dashboard.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useChatById } from 'hooks/api/useChatById';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export type SelectedFilterItem = {
  name: string;
  code: string;
} | null;

interface Props {
  tooltip: string;
  className?: string;
  filterClassName?: string;
  widgetType: InsightsType;
  selectedItem: SelectedFilterItem;
  notes: {
    active: string;
    inactive: string;
  };
}

export const WidgetFilter: FC<Props> = ({
  tooltip,
  notes,
  widgetType,
  filterClassName,
  selectedItem,
  className
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { chatId, dashboardId } = useParams();
  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();
  const [isApplyFilterModalOpen, setApplyFilterModalOpen] = useState(false);

  const { mutate: fetchChatData } = useChatById();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const onSuccess = () => {
    if (dashboardData?.chatId) {
      fetchChatData({ chatId: dashboardData.chatId });
    }
  };

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard(onSuccess);

  const sendMixpanelEvent = (mixpanelEvent: MixpanelEvents) => {
    mixpanel?.track(mixpanelEvent, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });
  };

  const onApplyFilter = () => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: {
        type: widgetType,
        value: selectedItem?.code || ''
      }
    });
  };

  const handleApplyFilterModalClose = () => {
    sendMixpanelEvent(MixpanelEvents.CancelFilterClick);

    setApplyFilterModalOpen(false);
  };
  const handleApplyFilterModalConfirm = () => {
    if (!hasCoins) {
      navigate(Routes.SubscriptionPlans, {
        state: { scrollToTop: true }
      });
      return;
    }

    sendMixpanelEvent(MixpanelEvents.ConfirmFilterClick);

    setApplyFilterModalOpen(false);
    onApplyFilter();
  };

  const handleFilterButtonClick = () => {
    sendMixpanelEvent(MixpanelEvents.FilterClick);

    if (!selectedItem) return null;

    if (userInfo?.isFreeUser) {
      setApplyFilterModalOpen(true);
    } else {
      onApplyFilter();
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      {isPending && (
        <Backdrop className={styles.overlay} open={isPending}>
          <div className={styles['overlay-content']}>
            <span className={styles.text}>{t('Common.NewDashboard')}</span>
            <div className={styles.spinner} />
          </div>
        </Backdrop>
      )}
      <DashboardFilterModal
        hasCoins={!!hasCoins}
        isOpen={isApplyFilterModalOpen}
        industry={selectedItem?.name || ''}
        onClose={handleApplyFilterModalClose}
        onConfirm={handleApplyFilterModalConfirm}
      />
      <div className={styles['filter-wrapper']}>
        <div className={cn(styles.filter, filterClassName)}>
          <CommonTooltip
            title={selectedItem?.name || tooltip}
            className={cn(
              styles.title,
              'overflowed-text',
              selectedItem && styles['has-value']
            )}
          />
        </div>
        <Button
          size="small"
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={handleFilterButtonClick}
          disabled={
            !selectedItem ||
            !!userInfo?.deactivatedAt ||
            isExternalUserDashboard
          }
        >
          {t('Common.ApplyFilter')}
        </Button>
      </div>
      <div className={styles.note}>
        <InfoIcon />

        {selectedItem ? notes.active : notes.inactive}
      </div>
    </div>
  );
};
