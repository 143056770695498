export enum Routes {
  Root = '/',
  Auth = '/auth',
  Login = '/login',
  VerifyUser = '/verify-user',
  Registration = '/registration',
  Confirm = '/confirm',
  UpdateEmailConfirm = '/update-email-confirm',
  Profile = '/profile/:tab?',
  IndustryChat = '/chats/industry/:industry',
  PublicIndustryChat = '/industry/:industry',
  Dashboard = '/chats/:chatId/dashboards/:dashboardId',
  ExternalUserDashboard = '/admin/chats/:chatId/messages/:dashboardId/widgets',
  Chat = '/chats/:chatId',
  NewChat = '/chats/new',
  SubscriptionPlans = '/subscription-plans',
  CoinsPackages = '/subscription-plans#coins-section',
  Checkout = '/checkout',
  OneTimePaymentCheckout = '/one-payment-checkout',
  SubscriptionConfirm = '/subscription-confirm',
  Unsubscribe = '/unsubscribe',
  ForgotPassword = '/forgot-password',
  RestorePassword = '/restore-password',
  ConfirmRestorePassword = '/confirm-restore-password',
  SubscriptionUpgradeConfirm = '/subscription-upgrade-confirm',
  CoinsPaymentConfirm = '/credits-confirm', // TODO: Change to '/coins-confirm'
  NewDevelopments = '/new-developments'
}
